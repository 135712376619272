<template>
  <div class="item">
    <button
      @click="action"
      :disabled="isDisabled"
      :class="[`ui button ${this.classes}`, loading ? 'loading' : '']"
    >
      <i :class="icon + ' icon'"></i>{{ label }}
    </button>
  </div>
</template>

<script>
export default {
  props: {
    action: Function,
    classes: String,
    icon: String,
    label: String,
    loading: Boolean,
    disabled: {
      type: Boolean,
      default: false
    },
    disabledOverride: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    isDisabled() {
      if (this.disabledOverride) {
        return false;
      } else {
        return this.disabled || this.$parent.isLoading;
      }
    }
  }
};
</script>
